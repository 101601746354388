module.exports = {
    // 1: {name: 'Ethereum', icon: ""},


    137: {
        chainId: 202212,
        name: 'Polygon',
        ticker: 'mXVT',
        native: 'MATIC',
        xenname: 'mXEN',
        baserate: BigInt(63372549),
        minimumFee: BigInt(1e17),
        divisor: BigInt(1e18),
        rpcurl: 'https://polygon.llamarpc.com',
        explorer: 'https://polygonscan.com',
        xen: '0x2AB0e9e4eE70FFf1fB9D67031E44F6410170d00e',
        enabled: false,
    },
    250: {
        chainId: 250,
        name: 'Fantom',
        ticker: 'fmXVT',
        native: 'FTM',
        xenname: 'fmXEN',
        baserate: BigInt(93372549),
        minimumFee: BigInt(1e17),
        divisor: BigInt(1e18),
        rpcurl: 'https://fantom.publicnode.com',
        explorer: 'https://ftmscan.com/',
        xen: '0xeF4B763385838FfFc708000f884026B8c0434275',
        xenvault: '0x294bDCddDB2C22C12e2Dc410E95a0dBF0C628d1B',
        xenvaultview: '0x2dfD18F7D058AF7868C77cD957652277f81d36b8',
        xenvaulterc20: '0x8b2267529B3D5CF286F18D9d1312e0b94650a444',
        enabled: true,
    },

    56: {
        chainId: 56,
        name: 'BSC',
        ticker: 'bXVT',
        native: 'BNB',
        xenname: 'bXEN',
        baserate: BigInt(23372549),
        minimumFee: BigInt(13e14),
        divisor: BigInt(1e19),
        rpcurl: 'https://bsc.publicnode.com',
        explorer: 'https://bscscan.com',
        xen: '0x2AB0e9e4eE70FFf1fB9D67031E44F6410170d00e',
        xenvault: '0x1BDb1Ca7aFE13Ae5B5724F17bceD66e7E5640cFe',
        xenvaultview: '0x2A0D00B5797C9417462B7FB137eF60F43A120844',
        xenvaulterc20: '0x206dc5426ac721Db8118b73Bc14aCA232d2a4Eea',
        enabled: true,
    },






    202212: {
      chainId: 202212,
      name: 'X1 Devnet',
      ticker: 'xdXVT',
      native: 'XN',
      xenname: 'XEN',
      baserate: BigInt(13372549),
      minimumFee: BigInt(1e17),
      divisor: BigInt(1e18),
      rpcurl: 'https://x1-devnet.xen.network',
      explorer: 'https://explorer.x1-devnet.xen.network/',
      xen: '0xD342D63466B520d8D331CaFF863900d402Aa5b00',
      xenvault: '0xeBccEca2e3F61F90a77498F7c157ac14842a2370',
      xenvaultview: '0xbA1791649D3e70Be1181bD7204e6Ef93308644A9',
      xenvaulterc20: '0xAb197A16a5c62c94961CA7dDE3C5d0fC31BC5e02',
      enabled: true,
    },
    4003: {
        chainId: 4003,
        name: 'X1 Fastnet',
        ticker: 'xfXVT',
        native: 'XN',
        xenname: 'XEN',
        baserate: BigInt(13372549),
        minimumFee: BigInt(1e17),
        divisor: BigInt(1e18),
        rpcurl: 'https://x1-fastnet.infrafc.org',
        explorer: 'https://explorer.x1-fastnet.infrafc.org/',
        xen: '0x784005E14a12545f22f53E6325bd2a2172237414',
        xenvault: '0x1BDb1Ca7aFE13Ae5B5724F17bceD66e7E5640cFe',
        xenvaultview: '0x2A0D00B5797C9417462B7FB137eF60F43A120844',
        xenvaulterc20: '0x206dc5426ac721Db8118b73Bc14aCA232d2a4Eea',
        enabled: true,
    },

    943: {
        chainId: 943,
        name: 'Pulsechain Testnet V4',
        ticker: 'pXVT',
        native: 'tPLS',
        xenname: 'pXEN',
        baserate: BigInt(93372549),
        minimumFee: BigInt(15e21),
        divisor: BigInt(1e14),
        rpcurl: 'https://rpc.v4.testnet.pulsechain.com',
        explorer: 'https://scan.v4.testnet.pulsechain.com',
        xen: '0x06450dEe7FD2Fb8E39061434BAbCFC05599a6Fb8'.toLowerCase(),
        xenvault: '0x2A0D00B5797C9417462B7FB137eF60F43A120844'.toLowerCase(),
        xenvaultview: '0xC712ba826c877AC883Adf1FC6Ec173962fB7794D'.toLowerCase(),
        xenvaulterc20: '0x154C978EeC9E8D27C15E7Ba1e69e0e7b6DCEF942'.toLowerCase(),
        enabled: false,
    },

    43112: {
      chainId: 43112,
      name: 'Localhost',
      ticker: 'lXVT',
      native: 'lETH',
      xenname: 'lXEN',
      baserate: BigInt(23372449),
      minimumFee: BigInt(13e14),
      divisor: BigInt(1e19),
      xen: '0xbc481dcc857C1f7472C81E2552E229B157b2B495',
      xenvault: '0x7c8f36Ac5DE6d254cEd4721252758eafdd362758',
      xenvaultview: '0xcd5cF3fD3F4a34AE3B233C702Ff38B96994e9e4D',
      xenvaulterc20: '0x92170d90C3Ab78D6841bf1f6DF39F9032347cc7c',
      enabled: false,
    }
}


