
import React, { useRef, useState, useContext, useEffect, useMemo } from 'react'
import toast from '../components/toast'
import { ethers, JsonRpcProvider } from 'ethers'

import { useAccount, useConnect } from 'wagmi'
import StakingTabs from '../components/StakingTabs'
import { useVault } from '../context/VaultContext'
import { initContracts, getSigner, getNetwork } from '../lib/contracts'

import Tooltip from '@mui/material/Tooltip';

export default function VaultLock() {
  const [stakingTab, setStakingTab] = useState('stake')
  const [network, setNetwork] = useState()
  const { address, isConnected } = useAccount()
  const {
    userStats,
    retrieveData,
  } = useVault()

  const lockXenNumberRef = useRef(null);
  const lockXenDaysRef = useRef(null);
  const vaultStakeAmountRef = useRef(null);
  const vaultUnStakeAmountRef = useRef(null);
  const number_of_days = 1000

  // handles labels
  const label = (network) ? network.native : '';
  const xenname = (network) ? network.xenname : '';
  useEffect(() => {
    async function retrieveNetwork() {
      setNetwork(await getNetwork())
    }

    retrieveNetwork()
  }, [])

  async function handleWalletChanges() {
    setNetwork(await getNetwork())
  }

  useEffect(() => {
    try {
      window.ethereum.on('accountsChanged', handleWalletChanges);
      window.ethereum.on('chainChanged', handleWalletChanges);
    } catch (e) {
      // console.log(e)
    }

    return () => {
      try {
        window.ethereum.removeListener('accountsChanged', handleWalletChanges);
        window.ethereum.removeListener('chainChanged', handleWalletChanges);
      } catch (e) {
      }
    };
  }, []);


  function maxXen(e) {
    e.preventDefault()
    lockXenNumberRef.current.value = Number(ethers.formatEther(userStats.xenBalance)).toLocaleString('en-us')
  }

  function maxStake(e) {
    e.preventDefault()
    vaultStakeAmountRef.current.value = Number(ethers.formatEther(userStats.vaultBalance)).toLocaleString('en-us')
  }

  function maxUnStake(e) {
    e.preventDefault()
    vaultUnStakeAmountRef.current.value = ethers.formatEther(userStats.withdrawbleStake)
  }

  function maxTerm(e) {
    e.preventDefault()
    lockXenDaysRef.current.value = number_of_days
  }

  function onTabChange(e) {
    setStakingTab(e)
  }


  function termDiscount(amount, term, rate)  {
    const baserate = rate * Number(amount) * term

    return BigInt((Math.sqrt(baserate) + baserate) / 1e4)
  }


  function amountDiscount(amount) {
    const base = BigInt(1e18)
    let baseFee = 14996848355000000000000n
    let multiplier = 170608n
    let divisor = 10000n
  
    let magnitude = 0
    let scaledAmount = amount
    while (scaledAmount >= 10n * base) {
      scaledAmount /= 10n
      magnitude++
    }
  
    for (let i = 0; i < magnitude; i++) {
      baseFee = (baseFee * multiplier) / divisor
    }
  
    const incrementFactor = BigInt('1348')
    let fee = baseFee + ((scaledAmount - base) * baseFee * incrementFactor) / (base * BigInt('1000'))
  
    return fee
  }



  async function handleLockXen(e) {
    e.preventDefault()
    return;
    const contracts = await initContracts()

    const amount = lockXenNumberRef.current.value.replaceAll(',', '')
    let _amount = ethers.parseEther(amount.toString() || '0')
    const term = lockXenDaysRef.current.value || 0
    const userXenBalance = await contracts.xen.balanceOf(address)

    if (_amount === 0n) {
      toast.error({
        "code": 4001,
        "title": 'XenVault',
        "message": 'Amount needs to be greater than 0'
      });
      return;
    } else if (_amount > 1e30) {
      toast.error({
        "code": 4001,
        "title": 'XenVault',
        "message": 'Amount is greater than max lock value'
      });
      return;
    } else if (term < 14) {
      toast.error({
        "code": 4001,
        "title": 'XenVault',
        "message": 'Minimum term not met'
      });
      return;
    } else if (_amount > userXenBalance) {
      toast.error({
        "code": 4001,
        "title": 'XenVault',
        "message": 'Amount is greater than wallet balance'
      });
      return;
    } else if (Number(term) === 0) {
      toast.error({
        "code": 4001,
        "title": 'XenVault',
        "message": 'Term needs to be greater than 0'
      });
      return;
    }

    try {
      const allowance = await contracts.xen.allowance(address, contracts.xenvault.target);

      if (allowance < _amount) {
        const approve_tx = await contracts.xen.approve(contracts.xenvault.target, _amount);        
        const approve_receipt = await approve_tx.wait()

        if (approve_receipt.status === 1) {
          toast.success({
            "title": 'XenVault',
            "message": 'Approval Success'
          });
        } else {
          toast.error({title: "Approving token failed"});
        }
      }

      const rate = network.baserate
      const base = _amount * rate * 125n / 100n
      const _termDiscount = termDiscount(_amount, term, Number(rate))
      const _amountDiscount = amountDiscount(_amount)

      const divisor = network.divisor ? network.divisor : BigInt(1e18)
      let protocolFee = (base - _termDiscount - _amountDiscount) / divisor
      const minimumFee = network.minimumFee

      if (protocolFee < minimumFee) {
        protocolFee = minimumFee
      }

      protocolFee = protocolFee * 130n / 100n

      const transaction = await contracts.xenvault.lockXen(_amount, term, { value: protocolFee })
      const receipt = await transaction.wait()

      if (receipt.status === 1) {
        toast.styled({title: "💎 Vault Successfully Locked! 🎉"})

        retrieveData()
      } else {
        toast.error({title: "Vault Lock Failed!!"});
      }
    } catch (e) {
      // console.log(e)

      if (e.code == 'CALL_EXCEPTION') {
        if (e.action == 'estimateGas') {
          return;
        }

        const msg = e.info.error.data.data.message ? 
          e.info.error.data.data.message : '';

        toast.error({
          "code": e.info.error.code,
          "title": e.info.error.message,
          "message": msg
        });
      } else if (e.code == 'ACTION_REJECTED') {
        toast.error({
          "code": e.info.error.code,
          "title": e.code,
          "message": e.info.error.message,
        });
      } else if (e.code == 'UNKNOWN_ERROR') {
        let errorMessage = false
        if (e.error.message.indexOf('value less than protocol fee') !== -1) {
          errorMessage = 'Xenvault: value less than protocol fee'
        }

        toast.error({
          "code": e.error.code,
          "title": e.code,
          "message": errorMessage ? errorMessage : e.shortMessage,
        });
      }
    }

  }

  async function handleClaimFeeRewards(e) {
    e.preventDefault()

    try {
      const contracts = await initContracts()
      const signer = await getSigner()
      const transaction = await contracts.xenvault.connect(signer).collectFees()
      const receipt = await transaction.wait()
      retrieveData()
    } catch (e) {
      // console.log(Object.keys(e))
      // console.log(e.code)

      if (e.code == 'CALL_EXCEPTION') {
        toast.error({
          "code": e.info.error.code,
          "title": e.info.error.message,
          "message": e.info.error.data.data.message
        });
      } else if (e.code == 'ACTION_REJECTED') {
        toast.error({
          "code": e.info.error.code,
          "title": e.code,
          "message": e.info.error.message,
        });
      }
    }
  }

  async function handleClaimXenFeeRewards(e) {
    e.preventDefault()

    try {
      const contracts = await initContracts()
      const signer = await getSigner()
      const transaction = await contracts.xenvault.connect(signer).collectXenFees()
      const receipt = await transaction.wait()
      retrieveData()
    } catch (e) {
      // console.log(e.code)
      if (e.code == 'CALL_EXCEPTION') {
        toast.error({
          "code": e.info.error.code,
          "title": e.info.error.message,
          "message": e.info.error.data.data.message
        });
      } else if (e.code == 'ACTION_REJECTED') {
        toast.error({
          "code": e.info.error.code,
          "title": e.code,
          "message": e.info.error.message,
        });
      }
    }
  }

  async function handleClaimVaultRewards(e) {
    e.preventDefault()

    try {
      const contracts = await initContracts()
      const signer = await getSigner()
      const transaction = await contracts.xenvault.connect(signer).collectRewards()
      const receipt = await transaction.wait()
      retrieveData()
    } catch (e) {
      // console.log(e)
      if (e.code == 'CALL_EXCEPTION') {
        toast.error({
          "code": e.info.error.code,
          "title": e.info.error.message,
          "message": e.info.error.data.data.message
        });
      } else if (e.code == 'ACTION_REJECTED') {
        toast.error({
          "code": e.info.error.code,
          "title": e.code,
          "message": e.info.error.message,
        });
      }
    }
  }

  async function handleStakeVault(e) {
    e.preventDefault()

    const contracts = await initContracts()
    const signer = await getSigner()
    const amount = vaultStakeAmountRef.current.value.replaceAll(',', '')
    let _amount = ethers.parseEther(amount.toString() || '0');
    const userBalance = await contracts.xenvaulterc20.balanceOf(address)

    if (_amount === 0n) {
      return;
    } else if (_amount > userBalance) {
      toast.error({
        "code": 4001,
        "title": 'XenVault',
        "message": 'Amount is greater than wallet balance'
      });
      return;
    }
  
    try {
      const allowance = await contracts.xenvaulterc20.allowance(address, contracts.xenvaulterc20.target);
      if (allowance < _amount) {
        await contracts.xenvaulterc20.approve(contracts.xenvault.target, _amount);
      }

      const transaction = await contracts.xenvault.connect(signer).stake(_amount)
      const receipt = await transaction.wait()
      retrieveData()
    } catch (e) {
      // console.log(e)
      if (e.code == 'CALL_EXCEPTION') {
        if (e.action == 'estimateGas') {
          return;
        }

        toast.error({
          "code": e.info.error.code,
          "title": e.info.error.message,
          "message": e.info.error.data.data.message
        });
      } else if (e.code == 'ACTION_REJECTED') {
        toast.error({
          "code": e.info.error.code,
          "title": e.code,
          "message": e.info.error.message,
        });
      }
    }
  }

  async function handleUnStakeVault(e) {
    e.preventDefault()

    const amount = vaultUnStakeAmountRef.current.value.replaceAll(',', '')
    let _amount = ethers.parseEther(amount.toString() || '0')

    if (userStats.totalStaked < _amount) {
      toast.error({
        "code": 4001,
        "title": 'XenVault',
        "message": 'Amount is greater than staked'
      });
      return;
    }

    try {
      const contracts = await initContracts()
      const signer = await getSigner()
      const transaction = await contracts.xenvault.connect(signer).unstake(_amount)
      const receipt = await transaction.wait()
      retrieveData()
    } catch (e) {
      // console.log(e)
      if (e.code == 'CALL_EXCEPTION') {
        toast.error({
          "code": e.info.error.code,
          "title": e.info.error.message,
          "message": e.info.error.data.data.message
        });
      } else if (e.code == 'ACTION_REJECTED') {
        toast.error({
          "code": e.info.error.code,
          "title": e.code,
          "message": e.info.error.message,
        });
      }
    }

  }

  function renderStakingUI() {

    return stakingTab == 'stake' ?
      <div className="py-8">
        <label forhtml="vault_stake_amount" className="relative block overflow-hidden border-b border-gray-200 bg-transparent pt-3 focus-within:border-blue-600">
          <input ref={vaultStakeAmountRef} type="text" id="vault_stake_amount" placeholder="vault_stake_amount" className="peer h-8 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm text-right" defaultValue="0" />
          <span className="absolute start-0 top-2 -translate-y-1/2 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs"> Amount </span>
        </label>
        <label className="flex justify-between mt-2">
          <span className="text-sm secondary-text">Number to Stake:</span>
          <span className="text-sm secondary-text grow-0 ml-auto">
            {Number(ethers.formatEther(userStats.vaultBalance)).toLocaleString('en-us')}
          </span>
          <span className="text-sm secondary-text">
            <button onClick={maxStake} type="button" className="primary-button-mini ml-2">Max</button>
          </span>
        </label>

        {
          isConnected ?
          <a onClick={handleStakeVault} className="cursor-pointer inline-block rounded bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[2px] text-gray-800 hover:text-white focus:outline-none focus:ring active:text-opacity-75 w-full text-center mt-8">
            <span className="block rounded-sm bg-white px-8 py-3 text-sm font-medium hover:bg-transparent">
              Stake Vault
            </span>
          </a> :
          <a onClick={() => connect({ connector })} className="cursor-pointer inline-block rounded bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[2px] text-gray-800 hover:text-white focus:outline-none focus:ring active:text-opacity-75 w-full text-center mt-8">
            <span className="block rounded-sm bg-white px-8 py-3 text-sm font-medium hover:bg-transparent">
              Connect Wallet
            </span>
          </a>
        }
      </div>
      :
      <div className="py-8">
        <label forhtml="vault_unstake_amount" className="relative block overflow-hidden border-b border-gray-200 bg-transparent pt-3 focus-within:border-blue-600">
          <input ref={vaultUnStakeAmountRef} type="text" id="vault_unstake_amount" placeholder="vault_unstake_amount" className="peer h-8 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm text-right" defaultValue="0" />
          <span className="absolute start-0 top-2 -translate-y-1/2 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs"> Amount </span>
        </label>
        <label className="flex justify-between mt-2">
          <span className="text-sm secondary-text">Number to Unstake:</span>
          <span className="text-sm secondary-text">
            <button onClick={maxUnStake} type="button" className="primary-button-mini ml-2">Max</button>
          </span>
        </label>

        {
          isConnected ?
          <a onClick={handleUnStakeVault} className="cursor-pointer inline-block rounded bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[2px] text-gray-800 hover:text-white focus:outline-none focus:ring active:text-opacity-75 w-full text-center mt-8">
            <span className="block rounded-sm bg-white px-8 py-3 text-sm font-medium hover:bg-transparent">
              Unstake Vault
            </span>
          </a> :
          <a onClick={() => connect({ connector })} className="cursor-pointer inline-block rounded bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[2px] text-gray-800 hover:text-white focus:outline-none focus:ring active:text-opacity-75 w-full text-center mt-8">
            <span className="block rounded-sm bg-white px-8 py-3 text-sm font-medium hover:bg-transparent">
              Connect Wallet
            </span>
          </a>
        }
      </div>

  }

  return (
    <div className="grid gap-x-4 md:grid-cols-2 py-4">
      <div className="p-4">
        <h2 className="text-2xl font-bold mb-4">Stats</h2>

        <div className="rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[3px] text-gray-800 mb-6">
          <span className="block rounded-lg bg-white dark:bg-[#0F172A] px-8 py-2 text-sm font-medium dark:text-white">
            <div className="flex justify-between mb-2">
              <div className="font-bold leading-none py-2">
                Number of Vaults:
              </div>
              <div className="leading-none py-2">
                {Number(userStats.totalVaults) || 0}
              </div>
            </div>

            <div className="flex justify-between">
              <div className="font-bold leading-none py-2">
                Amount locked:
              </div>
              <div className="leading-none py-2">
                {Number(userStats.totalLocked).toLocaleString('en-us') || 0}
              </div>
            </div>
          </span>
        </div>

        <div className="rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[3px] text-gray-800 mb-6">
          <span className="block rounded-lg bg-white dark:bg-[#0F172A] px-8 py-2 text-sm font-medium dark:text-white">
            <div className="flex justify-between">
              <div className="font-bold leading-none py-2">
                {label || 'ETH'} Rewards: 

                <Tooltip title="This comes from fees on locking xen">
                <svg className="ml-2 w-4 h-4 inline-block" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                </svg>
                </Tooltip>

              </div>
              <div className="leading-none py-2">
                <Tooltip title="This comes from fees on locking xen">
                {Number(userStats.feeReward).toFixed(8) || 0}
                </Tooltip>
              </div>
            </div>

            <div className="my-2">
              <button
                onClick={handleClaimFeeRewards}
                type="button" className={`${!isConnected && 'invisible '}inline-flex items-center justify-center py-2 px-3 rounded-lg bg-white text-gray-800 hover:text-green-500 text-sm font-semibold transition`}>
                Claim Reward
              </button>
            </div>
          </span>
        </div>

        <div className="rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[3px] text-gray-800 mb-6">
          <span className="block rounded-lg bg-white dark:bg-[#0F172A] px-8 py-2 text-sm font-medium dark:text-white">
          <div className="flex justify-between">
              <div className="font-bold leading-none py-2">
                {xenname || 'XEN'} Rewards:

                <Tooltip title="This comes from fees on locking xen">
                <svg className="ml-2 w-4 h-4 inline-block" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                </svg>
                </Tooltip>
              </div>
              
              <div className="leading-none py-2">
                <Tooltip title="This comes from fees on locking xen">
                {Number(userStats.xenFeeReward).toLocaleString('en-us') || 0}
                </Tooltip>
              </div>
            </div>

            <div className="my-2">
              <button
                onClick={handleClaimXenFeeRewards}
                title="Unclaimed rewards are automatically staked. You do not have to claim to stake."
                type="button" className={`${!isConnected && 'invisible '}inline-flex items-center justify-center py-2 px-3 rounded-lg bg-white text-gray-800 hover:text-green-500 text-sm font-semibold transition`}>
                Claim Reward
              </button>
            </div>
          </span>
        </div>

        <div className="rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[3px] text-gray-800 mb-6">
          <span className="block rounded-lg bg-white dark:bg-[#0F172A] px-8 py-2 text-sm font-medium dark:text-white">
            <div className="flex justify-between">
              <div className="font-bold leading-none py-2">
                Vault Rewards:
              </div>
              <div className="leading-none py-2">
                {Number(userStats.vaultReward).toLocaleString('en-us') || 0}
              </div>
            </div>
            <div className="font-thin text-[10.5px]">Unclaimed rewards are automatically staked.<br />You do not have to claim to stake.</div>
            <div className="my-2">
              <button
                onClick={handleClaimVaultRewards}
                type="button" className={`${!isConnected && 'invisible '}inline-flex items-center justify-center py-2 px-3 rounded-lg bg-white text-gray-800 hover:text-green-500 text-sm font-semibold transition`}>
                Claim Reward
              </button>
            </div>
          </span>
        </div>

        <div className="rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[3px] text-gray-800 mb-6">
          <span className="block rounded-lg bg-white dark:bg-[#0F172A] px-8 py-2 text-sm font-medium dark:text-white">
           <div className="flex justify-between">
              <div className="font-bold leading-none py-2">
                Staked Vault:

                <Tooltip title="It takes a cycle for newly staked XVT to accumulate rewards.">
                <svg className="ml-2 w-4 h-4 inline-block" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                </svg>
                </Tooltip>
              </div>
              <div className="leading-none py-2">
                {Number(userStats.totalStaked).toLocaleString('en-us') || 0}
              </div>
            </div>

            <div className="flex justify-between">
              <div className="font-bold leading-none py-2">
                Withdrawble Amount:

                <Tooltip title="Attention: Staked Tokens Require 1 Cycle to Withdraw">
                <svg className="ml-2 w-4 h-4 inline-block" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                </svg>
                </Tooltip>
              </div>
              <div className="leading-none py-2">
                {Number(ethers.formatEther(userStats.withdrawbleStake)).toLocaleString('en-us') || 0}
              </div>
            </div>

          </span>
        </div>

      </div>

      <div className="p-4">
        <h2 className="text-2xl font-bold mb-4">Lock Xen</h2>
        <div className="rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[3px] text-gray-800 mb-6">
          <span className="block rounded-lg bg-white dark:bg-[#0F172A] px-8 py-2 text-sm font-medium dark:text-white">

            <div className="py-8">
              <label forhtml="amount" className="relative block overflow-hidden border-b border-gray-200 bg-transparent pt-3 focus-within:border-blue-600">
                <Tooltip disableHoverListener title="Notice: 7% Entry Fee Applied to Principal Amount">
                  <input ref={lockXenNumberRef} type="text" id="amount" placeholder="amount" className="peer h-8 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm text-right" defaultValue="0" title="There's an entry fee when locking xen" />
                </Tooltip>
                <span className="absolute start-0 top-2 -translate-y-1/2 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs"> Amount </span>
              </label>
              <label className="flex justify-between mt-2">
                <span className="text-sm secondary-text">Number of Xen:</span>
                <span className="text-sm secondary-text grow-0 ml-auto">
                  {Number(ethers.formatEther(userStats.xenBalance)).toLocaleString('en-us')}
                </span>
                <span className="text-sm secondary-text">
                  <button onClick={maxXen} type="button" className="primary-button-mini ml-2">Max</button>
                </span>
              </label>
    

              <label forhtml="term" className="relative block overflow-hidden border-b border-gray-200 bg-transparent pt-3 focus-within:border-blue-600 mt-8">
                <input ref={lockXenDaysRef} type="text" id="term" placeholder="Days" className="peer h-8 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm text-right" defaultValue="14" min="14" />
                <span className="absolute start-0 top-2 -translate-y-1/2 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs"> Days </span>
              </label>
              <label className="flex justify-between mt-2">
                <span className="text-sm secondary-text">Number of Days: <br />14 days min</span>
                <span className="text-sm secondary-text grow-0 ml-auto">
                  {number_of_days}
                </span>
                <span className="text-sm secondary-text">
                  <button onClick={maxTerm} type="button" className="primary-button-mini ml-2">Max</button>
                </span>
              </label>

              {
                isConnected ?
                <a onClick={handleLockXen} className="cursor-pointer inline-block rounded bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[2px] text-gray-800 hover:text-white focus:outline-none focus:ring active:text-opacity-75 w-full text-center mt-8">
                  <span className="block rounded-sm bg-white px-8 py-3 text-sm font-medium hover:bg-transparent">
                    Lock Xen
                  </span>
                </a> :
                <a onClick={() => connect({ connector })} className="cursor-pointer inline-block rounded bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[2px] text-gray-800 hover:text-white focus:outline-none focus:ring active:text-opacity-75 w-full text-center mt-8">
                  <span className="block rounded-sm bg-white px-8 py-3 text-sm font-medium hover:bg-transparent">
                    Connect Wallet
                  </span>
                </a>
              }

            </div>

          </span>
        </div>

        <h2 className="text-2xl font-bold mb-4">Stake Vault</h2>
        <div className="rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[3px] text-gray-800 mb-6">
          <span className="block rounded-lg bg-white dark:bg-[#0F172A] px-8 py-2 text-sm font-medium dark:text-white">

            <StakingTabs onTabChange={setStakingTab} />
            {renderStakingUI()}
          </span>
        </div>

      </div>
    </div>
  )
}
